<template>
    <div class="background">
        <div v-for="(card, index) in stateData.currentSpinResult.cards"
             :key="index"
             class="cards"
        >
            <div :style="getCardPosition(index)"
                 class="card"
            >
                <img :src="cardImage(card)"/>

                <img v-if="isCardLocked(index)"
                     :src="holdImage"
                     class="hold"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        cards: [{
                            suit: null,
                            cost: null
                        }],
                        lockedCards: []
                    }
                }
            }
        }
    },
    data() {
        return {
            card: {
                width: 202
            },
            margin: {
                left: 25
            }
        }
    },
    computed: {
        backgroundImage() {
            return require('@/assets/games/poker/blues-poker/bg.png');
        },
        holdImage() {
            return require('@/assets/games/poker/blues-poker/hold.png');
        },
        getScaleStyle() {
            let y = this.viewport.width / this.default.width;

            return {
                transform: `scale(${y})`
            }
        },
    },
    methods: {
        cardImage(card) {
            let imageName = `${card.cost}_${card.suit}`;

            return require(`@/assets/games/poker/blues-poker/cards/${imageName}.png`);
        },
        getCardPosition(cardNumber) {
            let left = cardNumber * this.card.width + cardNumber * this.margin.left;

            return {
                left: `${left}px`
            };
        },
        isCardLocked(index) {
            let lockedCards = this.stateData.currentSpinResult.lockedCards;

            return !!(lockedCards && lockedCards[index]);
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 376);
        this.$store.dispatch('workspace/setWidth', 1238);
    }
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/poker/blues-poker/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1238px;
    height: 376px;
}

.cards {
    position: relative;

    top: 64px;
    left: 100px;
}

.card {
    position: absolute;
    background: transparent;
    border: 0;
}

.hold {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
